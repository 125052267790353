<template>
  <top></top>
  <div class="nav">
    <div class="status_img">
      <img src="~@/assets/img/zhifu_ing.png" alt="" />
    </div>
    <div class="status_text">{{ lang.v.progress }}</div>
    <div class="ret ret1" @click="ret">{{ lang.v.cancel }}</div>
    <div class="ret ret2" @click="zhifu_success">{{ lang.v.Paid }}</div>
  </div>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import router from '@/router';
import message from '@/utils/message';
import { status } from '@/api/fetcher';
import { lang } from '@/lang';

const ret = () => {
  router.go(-1);
};
const order_id = sessionStorage.getItem('mch_order_id');
const rechargeType = sessionStorage.getItem('rechargeType');
const warning11 = lang.v.warning11;
const zhifu_success = async () => {
  await status({
    orderNo: order_id.value,
  }).then(res => {
    if (res.data.data == false) {
      message.warning(warning11);
    } else if (res.data.data == true) {
      if (rechargeType.value == 1) {
        sessionStorage.setItem(
          'intelligent_id',
          JSON.stringify(userinfo.value.lastTalentCardType)
        );
        router.push('/become_intelligent');
      }
      if (rechargeType.value == 2) {
        router.push('/copyright');
      }
    }
  });
};
const userinfo = ref({});
onMounted(async () => {
  userinfo.value = JSON.parse(localStorage.getItem('userInfo'));
});
</script>
<style lang="less" scoped>
.nav {
  width: 100%;
  height: calc(100% - 0.62rem);
  background-color: rgba(255, 255, 255, 0.8);
  position: relative;

  .status_img {
    width: 1.21rem;
    height: 1.25rem;
    margin: auto;
    padding-top: 0.6rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .status_text {
    text-align: center;
    margin-top: 0.21rem;
    height: 0.28rem;
    font-size: 0.2rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #ed931e;
    line-height: 0.23rem;
  }

  .ret {
    width: 85%;
    height: 0.44rem;
    background: #f5f6f8;
    border-radius: 0.38rem;
    opacity: 1;
    text-align: center;
    line-height: 0.44rem;
    font-size: 0.16rem;
    color: #858c9f;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    position: absolute;
    bottom: 0.7rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .ret2 {
    bottom: 1.24rem;
    background: rgba(237, 147, 30, 0.16);
    color: #ed931e;
  }
}
</style>
